<!-- eslint-disable vue/no-v-html -->
<template>
  <div>
    <div v-if="sensors.length" class="place-map-legend sensors">
      <template v-for="(legend, index) in range[0][currentValue]">
        <span :key="`${legend.color}__${index}`" class="place-map-legend--item" :class="legend.color">
          <template v-if="range[0][currentValue][index + 1]">{{ legend.value }} -
            {{ range[0][currentValue][index + 1]['value'] }}</template>
          <template v-if="!range[0][currentValue][index + 1]">> {{ legend.value }}</template>
        </span>
      </template>
      <span class="place-map-legend--item white">{{
        $t('noSensorData')
      }}</span>
    </div>
    <div class="place-map">
      <div id="zoomOverlay" ref="zoomOverlay" class="place-map--zoom" :class="{
            show: isMobileDevice
          }">
        <font-awesome-icon icon="search-plus" />
      </div>
      <svg id="svgMap" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px"
        y="0px" :easypz="isMobileDevice
          ? easyPzSettingsMobile
          : easyPzSettingsDesktop
          " :viewBox="viewBox" :style="`enable-background:new ${viewBox};`" xml:space="preserve"
        @touchstart="isMobileDevice && removeZoomOverlay()">
        <defs>
          <linearGradient id="MotionGradient">
            <stop offset="5%" stop-color="#008000" />
            <stop offset="95%" stop-color="#98ffb3" />
          </linearGradient>
        </defs>
        <template v-if="currentFloor.svgSnippet">
          <g id="floorSvg" v-html="currentFloor.svgSnippet" />
        </template>
        <template id="desksReservationPage">
          <template v-if="currentRooms.length">
            <template v-for="(room, idx) in currentRooms">
              <g v-if="room.svgSnippet" :id="room.id" :key="`${room.id}__${idx}`" v-b-tooltip.hover class="img-room"
                :title="createReservationLabel(room)" :class="{
                  active: selectedObject.id === room.id,
                  free:
                    room.status === OCCUPANCY.FREE && room.reservable,
                  occupied:
                    room.status === OCCUPANCY.RESERVED ||
                    reservedDesksRoomIds.includes(room.id),
                  'not-reservable': !room.reservable,
                  myreservation: room.reservedForUser === currentUser
                }" :transform="transformString" @click="
  svgZindex(room.id), attemptRoomReservation(room)
  " v-html="room.svgSnippet" />
            </template>
          </template>
          <template v-for="(desk, idx) in freeDesks">
            <g :id="desk.id" :key="`${desk.id}__${idx}`" v-b-tooltip.hover class="img-desk free"
              :title="createReservationLabel(desk)" :class="{
                active: selectedObject.id === desk.id
              }" :transform="transformString" @click="
  svgZindex(desk.id),
  setSelectedObject(desk),
  $bvModal.show('modal-reserve')
  " v-html="desk.svgSnippet" />
          </template>
          <template v-for="(desk, idx) in unreservableDesks">
            <g :key="`${desk.id}__${idx}`" v-b-tooltip.hover class="img-desk not-reservable"
              :title="createReservationLabel(desk)" :transform="transformString" v-html="desk.svgSnippet" />
          </template>
          <template v-for="(desk, idx) in   reservedDesks  ">
            <g :key="`${desk.id}__${idx}`" v-b-tooltip.hover class="img-desk" :class="{
              myreservation: (desk.regularUser === null || desk.roomId in reservedRoomsIds) &&
                desk.reservedForUser === currentUser, occupied: desk.reservedForUser !== currentUser
            }" :title="createReservationLabel(desk)" :transform="transformString" @click="
  setSelectedObject(desk),
  $bvModal.show('modal-availability')
  " v-html="desk.svgSnippet" />
          </template>
          <template v-for="(  desk, idx  ) in   fixedDesks  ">
            <g :key="`${desk.id}__${idx}`" v-b-tooltip.hover class="img-desk" :class="{
              myreservation:
                desk.reservedForUser === currentUser ||
                desk.userId === currentUserId,
              occupied: desk.reservedForUser !== currentUser
            }
              " :title="createReservationLabel(desk)" :transform="transformString" @click="
    setSelectedObject(desk),
    $bvModal.show('modal-availability')
    " v-html="desk.svgSnippet" />
          </template>
        </template>
        <template id="sensorsPage">
          <template v-for="  sensor   in   sensors  ">
            <template v-if="sensorsShowed.indexOf(sensor.sensor_id) === -1">
              <template v-if="(currentValue === 'occupancy' ||
                currentValue === 'motion' ||
                currentValue === 'humidity' ||
                currentValue === 'temperature') &&
                (isAssignedToRoom(sensor) ||
                  isAssignedToDesk(sensor)) &&
                getRoomPrivacy(sensor) ===
                'summarize occupancy' &&
                !isCustomerAdmin &&
                !isDashboardAdmin
                ">
                {{ getRoomAverage(sensor) }}
              </template>
              <template v-else>
                <template v-if="isAssignedToDesk(sensor)">
                  <g v-if="sensorTypeMap[currentValue].indexOf(
                    sensor.sensor_type
                  ) !== -1
                    " :key="sensor.id" v-b-tooltip.hover class="img-desk" :transform="transformString" :title="$t('sensorType') +
    ': ' +
    sensor.sensor_type +
    '; ' +
    $t('sensorId') +
    ': ' +
    sensor.sensor_id +
    '; ' +
    (isAssignedToDesk(sensor)
      ? $t('desk') +
      ': ' +
      getSensorDeskName(sensor)
      : $t('room') +
      ': ' +
      getSensorRoomName(sensor)) +
    ';' +
    $t('lastMessage') +
    ': ' +
    getSensorLastMessage(sensor)
    " :class="[
    sensorColor(sensor[currentValue], currentValue),
    {
      active:
        sensor.sensor_id ===
        selectedSensor.sensor_id
    }
  ]
    " @click="
    setSelectedSensor(sensor),
    $bvModal.show('modal-sensor')
    " v-html="getDeskSvg(sensor)" />
                </template>
                <template v-else>
                  <g v-if="sensorTypeMap[currentValue].indexOf(
                    sensor.sensor_type
                  ) !== -1
                    " :key="sensor.id" v-b-tooltip.hover :transform="transformString" :title="$t('sensorType') +
    ': ' +
    sensor.sensor_type +
    '; ' +
    $t('sensorId') +
    ': ' +
    sensor.sensor_id +
    '; ' +
    (isAssignedToDesk(sensor)
      ? $t('desk') +
      ': ' +
      getSensorDeskName(sensor)
      : $t('room') +
      ': ' +
      getSensorRoomName(sensor)) +
    ';' +
    $t('lastMessage') +
    ': ' +
    getSensorLastMessage(sensor)
    " class="img-desk" :class="[
    sensorColor(sensor[currentValue], currentValue),
    {
      active:
        sensor.sensor_id ===
        selectedSensor.sensor_id
    }
  ]
    " @click="
    setSelectedSensor(sensor),
    $bvModal.show('modal-sensor')
    ">
                    <circle :cx="getSensorCoordinates(sensor)[0]" :cy="getSensorCoordinates(sensor)[1]" r="5" />
                  </g>
                </template>
              </template>
            </template>
          </template>
          <template v-for="(  average, index  ) in   averageOutput  ">
            <g :key="`${average.roomName}__${index}`" v-b-tooltip.hover :transform="transformString"
              :title="$t('room') + ': ' + average.roomName" v-html="average.svgSnippet" />
          </template>
        </template>
      </svg>
    </div>
    <div v-if="$route.name !== 'SensorsDataPage'" class="place-map-legend desks">
      <span class="place-map-legend--item free">{{
        $t('free')
      }}</span>
      <span class="place-map-legend--item occupied">{{ $t('occupied') }}/{{ $t('reserved') }}</span>
      <span v-if="!!currentUser.length" class="place-map-legend--item myreservation">{{ $t('myReservations') }}</span>
      <span v-if="showMovementLegend" class="place-map-legend--item motion">{{ $t('motion') }}</span>
    </div>
    <span :websocketUpdate="updatedObjectId" />
  </div>
</template>

<script>
import _get from 'lodash/get'
import moment from 'moment-timezone'
import { mapState, mapGetters } from 'vuex'
import ReservationMixin from '@/mixins/ReservationsMixin'

import { OCCUPANCY } from '@/constants'
import { RESET_EASYPZ_TRANSFORM_PARAMS } from '@/store/modules/common/mutationTypes'

export default {
  name: 'SvgMap',
  mixins: [ReservationMixin],
  props: {
    floors: {
      type: Array,
      default() {
        return []
      }
    },
    rooms: {
      type: Array,
      default() {
        return []
      }
    },
    currentRooms: {
      type: Array,
      default() {
        return []
      }
    },
    currentUser: {
      type: String,
      default() {
        return ''
      }
    },
    currentFloor: {
      type: Object,
      default: null
    },
    selectedObject: {
      type: Object,
      default() {
        return {}
      }
    },
    desks: {
      type: Array,
      default() {
        return []
      }
    },
    activeFloorDesks: {
      type: Array,
      default() {
        return []
      }
    },
    sensors: {
      type: Array,
      default() {
        return []
      }
    },
    currentValue: {
      type: String,
      default() {
        return ''
      }
    },
    selectedSensor: {
      type: Object,
      default() {
        return {}
      }
    },
    updatedObjectId: {
      type: String,
      default() {
        return ''
      }
    },
    currentUserId: {
      type: String,
      default() {
        return ''
      }
    },
    isKiosk: {
      type: Boolean,
      default() {
        return false
      }
    }
  },
  data() {
    return {
      OCCUPANCY,
      easyPzSettingsMobile: JSON.stringify({
        onTransformed: 'onEasyPzTransform',
        applyTransformTo: '#svgMap > *',
        modes: ['SIMPLE_PAN', 'WHEEL_ZOOM_EASE', 'PINCH_ZOOM'],
        options: {
          minScale: 1,
          maxScale: 4
        }
      }),
      easyPzSettingsDesktop: JSON.stringify({
        applyTransformTo: '#svgMap > *',
        modes: []
      }),
      sensorsShowed: [],
      averageOutput: [],
      sensorTypeMap: {
        temperature: [
          'okkuDesk',
          'okkuCO2',
          'officeComfort',
          'okku-sound',
          'okku-soil',
          'okku-room',
          'okku-eye',
          'iotta-co2',
          'iotta-desk'
        ],
        humidity: [
          'okkuDesk',
          'okkuCO2',
          'officeComfort',
          'okku-sound',
          'okku-soil',
          'okku-room',
          'okku-eye',
          'iotta-co2',
          'iotta-desk'
        ],
        light: [
          'okkuDesk',
          'okkuCO2',
          'officeComfort',
          'okku-sound',
          'okku-room',
          'okku-eye',
          'iotta-co2',
          'iotta-desk'
        ],
        motion: [
          'okkuDesk',
          'okkuCO2',
          'officeComfort',
          'okku-sound',
          'okku-room',
          'okku-eye',
          'iotta-co2',
          'iotta-desk'
        ],
        occupancy: ['okku-desksense', 'okkuDesk'],
        sound_avg: ['okku-sound'],
        co2: ['okkuCO2', 'iotta-co2'],
        counter: ['okku-people-counter', 'okku-door']
      },
      range: [
        {
          temperature: [
            { color: 'blue', value: 0 },
            { color: 'green', value: 18 },
            { color: 'orange', value: 25 },
            { color: 'red', value: 30 }
          ],
          co2: [
            { color: 'green', value: 0 },
            { color: 'yellow', value: 600 },
            { color: 'orange', value: 1000 },
            { color: 'red', value: 1400 }
          ],
          humidity: [
            { color: 'yellow', value: 0 },
            { color: 'green', value: 30 },
            { color: 'blue', value: 70 }
          ],
          sound_avg: [
            { color: 'green', value: 0 },
            { color: 'blue', value: 35 },
            { color: 'orange', value: 45 },
            { color: 'red', value: 80 }
          ],
          occupancy: [
            { color: 'green', value: 0 },
            { color: 'grey', value: 1 }
          ],
          motion: [
            { color: 'green', value: 0 },
            { color: 'blue', value: 3 },
            { color: 'orange', value: 8 },
            { color: 'orange', value: 8 },
            { color: 'red', value: 12 }
          ],
          light: [
            { color: 'darkGrey', value: 0 },
            { color: 'lightGrey', value: 100 },
            { color: 'blue', value: 300 },
            { color: 'green', value: 500 }
          ],
          counter: [
            { color: 'blue', value: 0 },
            { color: 'green', value: 100 },
            { color: 'orange', value: 300 },
            { color: 'red', value: 500 }
          ]
        }
      ]
    }
  },
  computed: {
    ...mapState('common', {
      isDashboardAdmin: '$isDashboardAdmin',
      isCustomerAdmin: '$isCustomerAdmin',
      organisation: '$organisation',
      reservationSettings: 'organisationReservationSettings'
    }),
    ...mapGetters('common', {
      buildingTimezone: 'buildingTimezone',
      isMobileDevice: 'isMobileDevice',
      userDetail: 'userDetail',
      isAdmin: 'isAdmin'
    }),
    ...mapState('common', [
      'dashboardSettings',
      'easyPzTransformParams'
    ]),
    transformString() {
      const {
        scale,
        translateX,
        translateY
      } = this.easyPzTransformParams
      return `scale(${scale},${scale})translate(${translateX},${translateY})`
    },
    showMovementLegend() {
      return !!this.dashboardSettings.showMovementLegend
    },
    freeDesks() {
      return this.activeFloorDesks.filter(
        desk =>
          desk.status === OCCUPANCY.FREE &&
          desk.reservable &&
          !this.reservedRoomsIds.includes(desk.roomId)
      )
    },
    reservedDesks() {
      return this.activeFloorDesks.filter(
        desk =>
          desk.status === OCCUPANCY.RESERVED ||
          (desk.reservable &&
            this.reservedRoomsIds.includes(desk.roomId))
      )
    },
    reservedDesksRoomIds() {
      const roomIds = []
      this.reservedDesks.forEach(desk => {
        if (!roomIds.includes(desk.roomId)) {
          roomIds.push(desk.roomId)
        }
      })
      return roomIds
    },
    reservedRoomsIds() {
      return this.currentRooms
        .filter(room => room.status === OCCUPANCY.RESERVED)
        .map(room => room.id)
    },
    unreservableDesks() {
      return this.activeFloorDesks.filter(desk => !desk.reservable)
    },
    fixedDesks() {
      return this.activeFloorDesks.filter(
        desk => desk.status === OCCUPANCY.FIXED && desk.reservable
      )
    },
    viewBox() {
      if (this.isKiosk) {
        const parser = new DOMParser();
        const svgDoc = parser.parseFromString(this.currentFloor.svgSnippet, 'text/html');
        const viewBox = svgDoc?.getElementsByTagName("svg")?.item(0)?.getAttribute("viewBox")
        console.log(`Got building viewBox ${viewBox}`)
        return viewBox
      }
      return "0 0 645 402"
    }
  },
  watch: {
    currentFloor() {
      this.averageOutput = []
    },
    currentValue() {
      this.averageOutput = []
      this.sensorsShowed = []
    }
  },
  beforeDestroy() {
    this.$store.commit(`common/${RESET_EASYPZ_TRANSFORM_PARAMS}`)
  },
  methods: {
    setSelectedObject(obj) {
      const selectedObject = obj
      this.$emit('updateSelectedObject', selectedObject)
    },
    setSelectedSensor(obj) {
      const selectedSensor = obj
      this.$emit('updateSelectedSensor', selectedSensor)
    },
    attemptRoomReservation(room) {
      if (
        !this.reservedDesksRoomIds.includes(room.id) &&
        room.reservable &&
        (room.status === OCCUPANCY.FREE ||
          room.status === OCCUPANCY.OCCUPIED)
      ) {
        this.setSelectedObject(room)
        this.$bvModal.show('modal-reserve')
      } else if (
        room.reservable &&
        (room.status === OCCUPANCY.RESERVED ||
          !this.reservedDesksRoomIds.includes(room.id))
      ) {
        this.setSelectedObject(room)
        this.$bvModal.show('modal-availability')
      }
    },
    svgZindex(item) {
      const svg = document.getElementById('svgMap')
      const element = document.getElementById(item)
      // TODO: why is this piece of code needed
      svg.appendChild(element)
    },
    getLabelReserveByFor(reservedByUserName, reservedForUserName) {
      return `, ${this.$t('reserved')} ${this.$t('by')}
                  ${reservedByUserName} ${this.$t(
        'for'
      )} ${reservedForUserName}`
    },
    getLabelReserveByForUntil(
      reservedByUserName,
      reservedForUserName,
      endTime
    ) {
      return `${this.getLabelReserveByFor(
        reservedByUserName,
        reservedForUserName
      )}${this.getLabelUntil(endTime)}`
    },
    getLabelReserveForUntil(reservedForUserName, endTime) {
      return `, ${this.$t('reserved')} ${this.$t('for')}
                  ${reservedForUserName} ${this.$t(
        'until'
      )} ${endTime}`
    },
    getLabelUntil(endTime) {
      return ` ${this.$t('until')} ${endTime}`
    },
    getLabelReserveFor(reservedForUserName) {
      return `, ${this.$t('reserved')} ${this.$t('for')}
                  ${reservedForUserName}`
    },
    createReservationLabel(object) {
      let {
        name,
        reservable,
        type,
        context = null,
        status,
        reservationEndDatetime,
        showUserDetails,
        reservationSubject,
        visibleToOthers,
        privacySettings,
        reservedForUser
      } = object
      let prettyNames
      let subject = this.$t('withSubject')
      if (reservationSubject === null) {
        reservationSubject = ''
        subject = ''
      }
      if (type === 'DESK')
        prettyNames = this.getDeskReservedFor(object)

      if (type === 'ROOM')
        prettyNames = this.getRoomReservedFor(object)

      let reservationLabel = name

      if (status === OCCUPANCY.FREE || !reservable) {
        return (
          reservationLabel +
          (!reservable ? ` ${this.$t('notAvailableAtThisTime')}` : '')
        )
      }

      const endTime = moment(reservationEndDatetime)
        .tz(this.buildingTimezone)
        .format('HH:mm')

      if (showUserDetails) {
        if (status === OCCUPANCY.FIXED) {
          reservationLabel += ` ${this.$t('reserved')} ${prettyNames}`
          if (
            visibleToOthers &&
            reservationSubject &&
            privacySettings === 'show all details'
          ) {
            reservationLabel += `${this.$t(
              'withSubject'
            )} ${reservationSubject}`
          }
        } else if (this.isAdmin) {
          reservationLabel += ` ${this.$t('reserved')} ${prettyNames}
          ${`${subject} ${reservationSubject}`}
          ${this.getLabelUntil(endTime)}`
        } else if (context && endTime) {
          reservationLabel += ` ${this.$t('reserved')} ${prettyNames}
          ${visibleToOthers &&
              reservationSubject &&
              privacySettings === 'show all details'
              ? `${this.$t('withSubject')} ${reservationSubject}`
              : ''
            }
          ${reservedForUser === this.userDetail &&
              !visibleToOthers &&
              reservationSubject &&
              privacySettings === 'show all details'
              ? `${this.$t('withSubject')} ${reservationSubject}`
              : ''
            }
          ${this.getLabelUntil(endTime)}`
        }
      } else if (status === OCCUPANCY.FIXED) {
        reservationLabel += `, ${this.$t('reserved')} / ${this.$t(
          'occupied'
        )}`
      } else {
        reservationLabel += `, ${this.$t('reserved')}
         ${this.getLabelUntil(endTime)}`
      }
      return reservationLabel
    },
    removeZoomOverlay() {
      this.$refs.zoomOverlay.classList.remove('show')
    },
    isAssignedToRoom(sensor) {
      return (
        this.rooms.filter(room => room.id === sensor.wmt_object_id)
          .length > 0
      )
    },
    isAssignedToDesk(sensor) {
      return (
        this.desks.filter(desk => desk.id === sensor.wmt_object_id)
          .length > 0
      )
    },
    getSensorRoomName(sensor) {
      const rooms = this.rooms.filter(
        room => room.id === sensor.wmt_object_id
      )
      if (rooms && rooms.length) {
        return rooms[0].name
      }
      if (this.getRoomById(sensor.wmt_object_id)) {
        return this.getRoomById(sensor.wmt_object_id)
      }
      return this.getRoomNameByDesk(sensor.wmt_object_id)

      // TODO: revise if room field is dropped from sensors table
    },
    getSensorDeskName(sensor) {
      const desks = this.desks.filter(
        desk => desk.id === sensor.wmt_object_id
      )
      if (desks && desks.length) {
        return desks[0].name
      }
      // TODO: revise if desk field is dropped from sensors table
      return sensor.desk
    },
    sensorColor(val, sensorVal) {
      let color = 'white'
      if (val != null && this.range[0][sensorVal]?.length) {
        for (let i = 0; i < this.range[0][sensorVal].length; i += 1) {
          if (val >= this.range[0][sensorVal][i].value) {
            // Since values are in ascending order this works better
            color = this.range[0][sensorVal][i].color
          }
        }
      }
      return color
    },
    getSensorCoordinates(currSensor) {
      let parent = {}
      let x = 0
      let y = 0
      if (currSensor.x || currSensor.y) {
        return [currSensor.x, currSensor.y]
      }
      if (currSensor.wmt_object_id != null) {
        this.desks.forEach((desk, index) => {
          if (currSensor.wmt_object_id === desk.id) {
            parent = desk
          }
        })
        this.rooms.forEach((room, index) => {
          if (currSensor.wmt_object_id === room.name) {
            parent = room
          }
        })

        if (parent.svgSnippet) {
          const snippet = parent.svgSnippet
          const svg = new DOMParser().parseFromString(
            snippet,
            'text/xml'
          )
          x =
            parseFloat(
              svg.getElementsByTagName('rect')[0].getAttribute('x')
            ) + 5
          y =
            parseFloat(
              svg.getElementsByTagName('rect')[0].getAttribute('y')
            ) + 10
        }
      } else {
        x = this.generateRandomNumber(100)
        y = this.generateRandomNumber(100)
      }
      return [x, y]
    },
    generateRandomNumber(val) {
      return Math.floor(Math.random() * val) + 1
    },
    getDeskSvg(sensor) {
      return this.desks.find(desk => desk.id === sensor.wmt_object_id)
        ?.svgSnippet
    },
    getSensorLastMessage(sensor) {
      return moment(sensor.datetime).format('MMM Do YYYY, H:mm')
    },
    getRoomById(roomId) {
      return this.rooms.find(room => room.id === roomId)?.name
    },
    getRoomByDesk(deskId) {
      return this.desks.find(desk => desk.id === deskId)?.roomId
    },
    getRoomNameByDesk(deskId) {
      return this.desks.find(desk => desk.id === deskId)?.roomName
    },
    getRoomPrivacy(sensor) {
      let rooms

      if (this.isAssignedToDesk(sensor)) {
        rooms = this.rooms.filter(
          room => room.id === this.getRoomByDesk(sensor.wmt_object_id)
        )
      } else {
        rooms = this.rooms.filter(
          room => room.id === sensor.wmt_object_id
        )
      }
      return _get(rooms, '[0].settings.privacySetting', '')
    },
    getAveragPosition(roomName, width, height) {
      const desks = this.desks.filter(
        desk => desk.roomName === roomName
      )
      const sensorArray = []
      let minLeft = 0
      let maxRight = 0
      let minTop = 0
      let maxBottom = 0
      let x = 0
      let y = 0
      desks.forEach((desk, index) => {
        if (desk.svgSnippet) {
          const snippet = desk.svgSnippet
          const svg = new DOMParser().parseFromString(
            snippet,
            'text/xml'
          )

          sensorArray.push({
            id: index,
            x: parseFloat(
              svg.getElementsByTagName('rect')[0].getAttribute('x')
            ),
            width: parseFloat(
              svg
                .getElementsByTagName('rect')[0]
                .getAttribute('width')
            ),
            y: parseFloat(
              svg.getElementsByTagName('rect')[0].getAttribute('y')
            ),
            height: parseFloat(
              svg
                .getElementsByTagName('rect')[0]
                .getAttribute('height')
            )
          })
        }
      })

      sensorArray.forEach((item, index) => {
        if (minLeft === 0 && maxRight === 0) {
          minLeft = item.x
          maxRight = item.x + item.width - 0.1
        } else if (item.x < minLeft) {
          minLeft = item.x
        } else if (item.x > maxRight) {
          maxRight = item.x + item.width - 0.1
        }

        if (minTop === 0 && maxBottom === 0) {
          minTop = item.y
          maxBottom = item.y + item.height - 0.1
        } else if (item.y < minTop) {
          minTop = item.y
        } else if (item.y > maxBottom) {
          maxBottom = item.y + item.height - 0.1
        }
      })

      x = minLeft + (maxRight - minLeft) / 2 - width / 2
      y = minTop + (maxBottom - minTop) / 2 - height / 2
      return { x, y }
    },
    getRoomAverage(currentSensor) {
      let output = ''
      let roomName = ''
      if (this.isAssignedToDesk(currentSensor)) {
        roomName = this.getRoomNameByDesk(currentSensor.wmt_object_id)
      } else {
        roomName = this.getRoomById(currentSensor.wmt_object_id)
      }
      if (
        this.sensorTypeMap[this.currentValue].indexOf(
          currentSensor.sensor_type
        ) !== -1 &&
        this.sensorsShowed.indexOf(currentSensor.sensor_id) === -1
      ) {
        const roomSensors = this.sensors.filter(
          sensor =>
            currentSensor.wmt_object_id === sensor.wmt_object_id
        )
        this.sensors.forEach((sensor, index) => {
          if (
            this.getSensorRoomName(sensor) ===
            this.getSensorRoomName(currentSensor) &&
            this.sensorTypeMap[this.currentValue].indexOf(
              sensor.sensor_type
            ) !== -1 &&
            this.sensorsShowed.indexOf(sensor.sensor_id) === -1
          ) {
            this.sensorsShowed.push(sensor.sensor_id)
            roomSensors.push(sensor)
          }
        })

        if (this.isAssignedToDesk(currentSensor)) {
          roomName = this.getRoomNameByDesk(
            currentSensor.wmt_object_id
          )
          let deskRoomSensors = []
          deskRoomSensors = this.sensors.filter(
            sensor =>
              this.getRoomByDesk(currentSensor.wmt_object_id) ===
              sensor.wmt_object_id
          )
          if (deskRoomSensors && deskRoomSensors.length) {
            for (let i = 0; i < deskRoomSensors[0].length; i += 1) {
              if (
                this.sensorTypeMap[this.currentValue].indexOf(
                  deskRoomSensors[0][i].sensor_type
                ) !== -1 &&
                this.sensorsShowed.indexOf(
                  deskRoomSensors[0][i].sensor_id
                ) === -1
              ) {
                this.sensorsShowed.push(
                  deskRoomSensors[0][i].sensor_id
                )
                roomSensors.push(deskRoomSensors[0][i])
              }
            }
          }

          const deskRoomDesks = this.desks.filter(
            desk =>
              this.getRoomByDesk(currentSensor.wmt_object_id) ===
              desk.roomId
          )

          let deskSensors = []
          for (let i = 0; i < deskRoomDesks.length; i += 1) {
            deskSensors = this.sensors.filter(sensor => {
              if (
                this.sensorTypeMap[this.currentValue].indexOf(
                  sensor.sensor_type
                ) !== -1 &&
                this.sensorsShowed.indexOf(sensor.sensor_id) === -1
              ) {
                return deskRoomDesks[i].id === sensor.wmt_object_id
              }
              return false
            })
          }
          if (deskSensors && deskSensors.length) {
            for (let i = 0; i < deskSensors[0].length; i += 1) {
              if (
                this.sensorTypeMap[this.currentValue].indexOf(
                  deskSensors[0][i].sensor_type
                ) !== -1 &&
                this.sensorsShowed.indexOf(
                  deskSensors[0][i].sensor_id
                ) === -1
              ) {
                this.sensorsShowed.push(deskSensors[0][i].sensor_id)
                roomSensors.push(deskSensors[0][i])
              }
            }
          }
        }

        if (
          this.currentValue === 'humidity' ||
          this.currentValue === 'temperature'
        ) {
          let total = 0
          let average = 0
          let counter = 0
          for (let i = 0; i < roomSensors.length; i += 1) {
            this.sensorsShowed.push(roomSensors[i].sensor_id)
            if (parseFloat(roomSensors[i][this.currentValue])) {
              total += parseFloat(roomSensors[i][this.currentValue])
              counter += 1
            }
          }
          average = (total / counter).toFixed(2)
          const posObject = this.getAveragPosition(roomName, 26, 12)
          output = `${output}<rect class="room-average-progress" id="${currentSensor.sensor_id
            }" x="${posObject.x}" y="${parseFloat(
              posObject.y
            )}" width="26px" height="12px" fill="rgba(84,64,165,0.9)"></rect>`
          output = `${output}<rect x="${parseFloat(
            26 + posObject.x
          )}" y="${parseFloat(
            posObject.y + 3
          )}" width="2px" height="6px" fill="rgba(84,64,165,0.9)"></rect>`
          output = `${output}<text x="${parseFloat(
            3 + posObject.x
          )}" y="${parseFloat(
            posObject.y + 8
          )}" font-family="Verdana" font-size="7px" fill="white">${average}</text>`
        } else {
          let countBusy = 0
          let countFree = 0
          let contWidth = 2
          for (let i = 0; i < roomSensors.length; i += 1) {
            if (
              this.sensorTypeMap[this.currentValue].indexOf(
                roomSensors[i].sensor_type
              ) !== -1
            ) {
              this.sensorsShowed.push(roomSensors[i].sensor_id)
              if (roomSensors[i][this.currentValue] > 0) {
                countBusy += 1
              } else {
                countFree += 1
              }
              contWidth += 4
            }
          }

          if (countBusy > 0 || countFree > 0) {
            let currleft = 2
            const posObject = this.getAveragPosition(
              roomName,
              contWidth,
              12
            )
            output = `${output}<rect class="room-average-progress" id="${currentSensor.sensor_id}" x="${posObject.x}" y="${posObject.y}" width="${contWidth}px" height="12px" fill="rgba(84,64,165,0.9)"></rect>`
            output = `${output}<rect x="${parseFloat(
              contWidth + posObject.x
            )}" y="${parseFloat(
              posObject.y + 4
            )}" width="2px" height="6px" fill="rgba(84,64,165,0.9)"></rect>`
            for (let i = 0; i < countBusy; i += 1) {
              output = `${output}<rect width="2" y="${parseFloat(
                posObject.y + 2
              )}" x="${parseFloat(
                posObject.x + currleft
              )}" height="8" fill="green"></rect>`
              currleft += 4
            }
            for (let i = 0; i < countFree; i += 1) {
              output = `${output}<rect width="2" y="${parseFloat(
                posObject.y + 2
              )}" x="${parseFloat(
                posObject.x + currleft
              )}" height="8" fill="white"></rect>`
              currleft += 4
            }
          }
        }

        this.averageOutput.push({
          svgSnippet: output,
          roomName: this.getSensorRoomName(currentSensor)
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../assets/scss/globals/vars.scss';
@import '../../assets/scss/globals/mixins.scss';
@import '../../assets/scss/globals/extend.scss';

.place-map::v-deep {
  position: relative;
  margin-top: 1rem;
  padding-top: 0;
  border: 1px solid #ddd;
  border-radius: 4px;

  .edit-svg {
    position: absolute;
    top: 0;
    right: 0;
  }

  .img-desk {
    cursor: pointer;

    &:hover {
      opacity: 0.65;
    }

    &.free {
      * {
        fill: rgba(0, 128, 0, 0.8);
      }
    }

    &.not-reservable {
      cursor: default;

      * {
        fill: transparent;
        stroke: #000;
      }
    }

    &.occupied,
    &.reserved {
      cursor: default;

      * {
        fill: rgba(128, 128, 128, 0.8);
      }
    }

    &.myreservation {
      cursor: default;

      * {
        fill: rgba(255, 255, 0, 0.8);
        stroke: #000;
      }
    }

    &.active {
      rect {
        stroke: #000;
        stroke-width: 3px;
        /*fill: #fe8200 !important;*/
      }
    }

    &.green {

      circle,
      rect {
        fill: green;
      }
    }

    &.yellow {

      circle,
      rect {
        fill: #feec26;
      }
    }

    &.grey {

      circle,
      rect {
        fill: gray;
      }
    }

    &.darkGrey {

      circle,
      rect {
        fill: #343434;
      }
    }

    &.lightGrey {

      circle,
      rect {
        fill: #cbcbcb;
      }
    }

    &.orange {

      circle,
      rect {
        fill: #ff7420;
      }
    }

    &.red {

      circle,
      rect {
        fill: #ff1625;
      }
    }

    &.blue {

      circle,
      rect {
        fill: #625cff;
      }
    }

    &.white {

      circle,
      rect {
        fill: rgba(#000, 0.1);
        stroke: grey;
        stroke-dasharray: 2;
        stroke-width: 1px;
      }
    }

    &.active {

      circle,
      rect {
        stroke: #000;
        stroke-width: 1px;
      }
    }
  }

  .img-room {
    cursor: pointer;

    &:hover {
      opacity: 0.8;
    }

    &.not-reservable {
      cursor: default;

      * {
        fill: transparent;
        stroke: #000;
      }
    }

    &.free {
      * {
        fill: rgba(green, 0.65);
      }
    }

    &.occupied,
    &.reserved {
      cursor: default;

      * {
        fill: gray;
      }
    }

    &.myreservation {
      //pointer-events: none;
      cursor: default;

      * {
        fill: yellow;
        stroke: gray;
      }
    }

    &.active {
      rect {
        stroke: #000;
        stroke-width: 3px;
        /*fill: #fe8200 !important;*/
      }
    }
  }
}

.place-map--zoom {
  position: absolute;
  @extend %cam;
  /*background-color: rgba(255,255,255,.6);*/

  display: none;
  transition: all $time;

  .svg-inline--fa {
    font-size: 24px;
    color: $basecolor;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  &.show {
    display: block;

    &+svg {
      opacity: 0.6;
    }
  }
}

.place-map:hover,
.place-map:active,
.place-map:focus {
  .place-map--zoom {
    opacity: 0;
    visibility: hidden;

    &+svg {
      opacity: 1;
    }
  }
}

.place-map-legend.desks {
  display: flex;
  margin-top: 1rem;

  @include r(767) {
    flex-direction: column;
  }

  .place-map-legend--item {
    position: relative;
    display: inline-block;
    vertical-align: top;
    margin-right: 10px;
    font-weight: 300;
    font-size: 14px;
    padding-left: 30px;

    @include r(767) {
      margin-top: 0.5rem;
      font-size: 0.8rem;
    }

    @include r(400) {
      margin-left: 0;
      margin-right: 10px;
    }

    &:before {
      content: '';
      position: absolute;
      display: block;
      left: 0;
      top: 50%;
      margin-top: -7px;
      height: 12px;
      width: 24px;
    }

    &:last-child {
      margin-right: 0;
    }

    &.free {
      &:before {
        background-color: rgba(0, 128, 0, 0.8);
      }
    }

    &.occupied {
      &:before {
        background-color: rgba(128, 128, 128, 0.8);
      }
    }

    &.myreservation {
      &:before {
        background-color: rgba(255, 255, 0, 0.8);
        border: 1px solid grey;
      }
    }

    &.motion {
      &:before {
        background: linear-gradient(to right,
            #008000 5%,
            #98ffb3 95%);
        opacity: 0.8;
      }
    }
  }
}

.place-map-legend.sensors {
  margin-bottom: 8px;
  margin-top: 10px;

  @include r(767) {
    margin-bottom: 0;
  }

  @include r(400) {
    float: none;
    text-align: left;
    margin-top: 5px;
    max-width: 100%;
  }

  .place-map-legend--item {
    position: relative;
    display: inline-block;
    vertical-align: top;
    margin-right: 10px;
    font-weight: 300;
    font-size: 14px;

    padding-left: 18px;

    @include r(767) {
      font-size: 12px;
    }

    @include r(400) {
      margin-left: 0;
      margin-right: 10px;
    }

    &:before {
      content: '';
      position: absolute;
      display: block;
      left: 0;
      top: 50%;
      margin-top: -6px;
      height: 12px;
      width: 12px;
      border-radius: 50%;
    }

    &:last-child {
      margin-right: 0;
    }

    &.green {
      &:before {
        background-color: green;
      }
    }

    &.yellow {
      &:before {
        background-color: #feec26;
      }
    }

    &.grey {
      &:before {
        background-color: gray;
      }
    }

    &.darkGrey {
      &:before {
        background-color: #343434;
      }
    }

    &.lightGrey {
      &:before {
        background-color: #cbcbcb;
      }
    }

    &.orange {
      &:before {
        background-color: #ff7420;
        /*border-radius: 6px;*/
      }
    }

    &.red {
      &:before {
        background-color: #ff1625;
      }
    }

    &.blue {
      &:before {
        background-color: #625cff;
      }
    }

    &.white {
      &:before {
        background: rgba(#000, 0.1);
        border: 1px dashed grey;
      }
    }
  }
}
</style>
