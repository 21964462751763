var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.sensors.length)?_c('div',{staticClass:"place-map-legend sensors"},[_vm._l((_vm.range[0][_vm.currentValue]),function(legend,index){return [_c('span',{key:((legend.color) + "__" + index),staticClass:"place-map-legend--item",class:legend.color},[(_vm.range[0][_vm.currentValue][index + 1])?[_vm._v(_vm._s(legend.value)+" - "+_vm._s(_vm.range[0][_vm.currentValue][index + 1]['value']))]:_vm._e(),(!_vm.range[0][_vm.currentValue][index + 1])?[_vm._v("> "+_vm._s(legend.value))]:_vm._e()],2)]}),_c('span',{staticClass:"place-map-legend--item white"},[_vm._v(_vm._s(_vm.$t('noSensorData')))])],2):_vm._e(),_c('div',{staticClass:"place-map"},[_c('div',{ref:"zoomOverlay",staticClass:"place-map--zoom",class:{
          show: _vm.isMobileDevice
        },attrs:{"id":"zoomOverlay"}},[_c('font-awesome-icon',{attrs:{"icon":"search-plus"}})],1),_c('svg',{style:(("enable-background:new " + _vm.viewBox + ";")),attrs:{"id":"svgMap","version":"1.1","xmlns":"http://www.w3.org/2000/svg","xmlns:xlink":"http://www.w3.org/1999/xlink","x":"0px","y":"0px","easypz":_vm.isMobileDevice
        ? _vm.easyPzSettingsMobile
        : _vm.easyPzSettingsDesktop,"viewBox":_vm.viewBox,"xml:space":"preserve"},on:{"touchstart":function($event){_vm.isMobileDevice && _vm.removeZoomOverlay()}}},[_c('defs',[_c('linearGradient',{attrs:{"id":"MotionGradient"}},[_c('stop',{attrs:{"offset":"5%","stop-color":"#008000"}}),_c('stop',{attrs:{"offset":"95%","stop-color":"#98ffb3"}})],1)],1),(_vm.currentFloor.svgSnippet)?[_c('g',{attrs:{"id":"floorSvg"},domProps:{"innerHTML":_vm._s(_vm.currentFloor.svgSnippet)}})]:_vm._e(),[(_vm.currentRooms.length)?[_vm._l((_vm.currentRooms),function(room,idx){return [(room.svgSnippet)?_c('g',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],key:((room.id) + "__" + idx),staticClass:"img-room",class:{
                active: _vm.selectedObject.id === room.id,
                free:
                  room.status === _vm.OCCUPANCY.FREE && room.reservable,
                occupied:
                  room.status === _vm.OCCUPANCY.RESERVED ||
                  _vm.reservedDesksRoomIds.includes(room.id),
                'not-reservable': !room.reservable,
                myreservation: room.reservedForUser === _vm.currentUser
              },attrs:{"id":room.id,"title":_vm.createReservationLabel(room),"transform":_vm.transformString},domProps:{"innerHTML":_vm._s(room.svgSnippet)},on:{"click":function($event){_vm.svgZindex(room.id), _vm.attemptRoomReservation(room)}}}):_vm._e()]})]:_vm._e(),_vm._l((_vm.freeDesks),function(desk,idx){return [_c('g',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],key:((desk.id) + "__" + idx),staticClass:"img-desk free",class:{
              active: _vm.selectedObject.id === desk.id
            },attrs:{"id":desk.id,"title":_vm.createReservationLabel(desk),"transform":_vm.transformString},domProps:{"innerHTML":_vm._s(desk.svgSnippet)},on:{"click":function($event){_vm.svgZindex(desk.id),
_vm.setSelectedObject(desk),
_vm.$bvModal.show('modal-reserve')}}})]}),_vm._l((_vm.unreservableDesks),function(desk,idx){return [_c('g',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],key:((desk.id) + "__" + idx),staticClass:"img-desk not-reservable",attrs:{"title":_vm.createReservationLabel(desk),"transform":_vm.transformString},domProps:{"innerHTML":_vm._s(desk.svgSnippet)}})]}),_vm._l((_vm.reservedDesks),function(desk,idx){return [_c('g',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],key:((desk.id) + "__" + idx),staticClass:"img-desk",class:{
            myreservation: (desk.regularUser === null || desk.roomId in _vm.reservedRoomsIds) &&
              desk.reservedForUser === _vm.currentUser, occupied: desk.reservedForUser !== _vm.currentUser
          },attrs:{"title":_vm.createReservationLabel(desk),"transform":_vm.transformString},domProps:{"innerHTML":_vm._s(desk.svgSnippet)},on:{"click":function($event){_vm.setSelectedObject(desk),
_vm.$bvModal.show('modal-availability')}}})]}),_vm._l((_vm.fixedDesks),function(desk,idx){return [_c('g',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],key:((desk.id) + "__" + idx),staticClass:"img-desk",class:{
            myreservation:
              desk.reservedForUser === _vm.currentUser ||
              desk.userId === _vm.currentUserId,
            occupied: desk.reservedForUser !== _vm.currentUser
          },attrs:{"title":_vm.createReservationLabel(desk),"transform":_vm.transformString},domProps:{"innerHTML":_vm._s(desk.svgSnippet)},on:{"click":function($event){_vm.setSelectedObject(desk),
  _vm.$bvModal.show('modal-availability')}}})]})],[_vm._l((_vm.sensors),function(sensor){return [(_vm.sensorsShowed.indexOf(sensor.sensor_id) === -1)?[((_vm.currentValue === 'occupancy' ||
              _vm.currentValue === 'motion' ||
              _vm.currentValue === 'humidity' ||
              _vm.currentValue === 'temperature') &&
              (_vm.isAssignedToRoom(sensor) ||
                _vm.isAssignedToDesk(sensor)) &&
              _vm.getRoomPrivacy(sensor) ===
              'summarize occupancy' &&
              !_vm.isCustomerAdmin &&
              !_vm.isDashboardAdmin
              )?[_vm._v(" "+_vm._s(_vm.getRoomAverage(sensor))+" ")]:[(_vm.isAssignedToDesk(sensor))?[(_vm.sensorTypeMap[_vm.currentValue].indexOf(
                  sensor.sensor_type
                ) !== -1
                  )?_c('g',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],key:sensor.id,staticClass:"img-desk",class:[
  _vm.sensorColor(sensor[_vm.currentValue], _vm.currentValue),
  {
    active:
      sensor.sensor_id ===
      _vm.selectedSensor.sensor_id
  }
],attrs:{"transform":_vm.transformString,"title":_vm.$t('sensorType') +
  ': ' +
  sensor.sensor_type +
  '; ' +
  _vm.$t('sensorId') +
  ': ' +
  sensor.sensor_id +
  '; ' +
  (_vm.isAssignedToDesk(sensor)
    ? _vm.$t('desk') +
    ': ' +
    _vm.getSensorDeskName(sensor)
    : _vm.$t('room') +
    ': ' +
    _vm.getSensorRoomName(sensor)) +
  ';' +
  _vm.$t('lastMessage') +
  ': ' +
  _vm.getSensorLastMessage(sensor)},domProps:{"innerHTML":_vm._s(_vm.getDeskSvg(sensor))},on:{"click":function($event){_vm.setSelectedSensor(sensor),
  _vm.$bvModal.show('modal-sensor')}}}):_vm._e()]:[(_vm.sensorTypeMap[_vm.currentValue].indexOf(
                  sensor.sensor_type
                ) !== -1
                  )?_c('g',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],key:sensor.id,staticClass:"img-desk",class:[
  _vm.sensorColor(sensor[_vm.currentValue], _vm.currentValue),
  {
    active:
      sensor.sensor_id ===
      _vm.selectedSensor.sensor_id
  }
],attrs:{"transform":_vm.transformString,"title":_vm.$t('sensorType') +
  ': ' +
  sensor.sensor_type +
  '; ' +
  _vm.$t('sensorId') +
  ': ' +
  sensor.sensor_id +
  '; ' +
  (_vm.isAssignedToDesk(sensor)
    ? _vm.$t('desk') +
    ': ' +
    _vm.getSensorDeskName(sensor)
    : _vm.$t('room') +
    ': ' +
    _vm.getSensorRoomName(sensor)) +
  ';' +
  _vm.$t('lastMessage') +
  ': ' +
  _vm.getSensorLastMessage(sensor)},on:{"click":function($event){_vm.setSelectedSensor(sensor),
  _vm.$bvModal.show('modal-sensor')}}},[_c('circle',{attrs:{"cx":_vm.getSensorCoordinates(sensor)[0],"cy":_vm.getSensorCoordinates(sensor)[1],"r":"5"}})]):_vm._e()]]]:_vm._e()]}),_vm._l((_vm.averageOutput),function(average,index){return [_c('g',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],key:((average.roomName) + "__" + index),attrs:{"transform":_vm.transformString,"title":_vm.$t('room') + ': ' + average.roomName},domProps:{"innerHTML":_vm._s(average.svgSnippet)}})]})]],2)]),(_vm.$route.name !== 'SensorsDataPage')?_c('div',{staticClass:"place-map-legend desks"},[_c('span',{staticClass:"place-map-legend--item free"},[_vm._v(_vm._s(_vm.$t('free')))]),_c('span',{staticClass:"place-map-legend--item occupied"},[_vm._v(_vm._s(_vm.$t('occupied'))+"/"+_vm._s(_vm.$t('reserved')))]),(!!_vm.currentUser.length)?_c('span',{staticClass:"place-map-legend--item myreservation"},[_vm._v(_vm._s(_vm.$t('myReservations')))]):_vm._e(),(_vm.showMovementLegend)?_c('span',{staticClass:"place-map-legend--item motion"},[_vm._v(_vm._s(_vm.$t('motion')))]):_vm._e()]):_vm._e(),_c('span',{attrs:{"websocketUpdate":_vm.updatedObjectId}})])}
var staticRenderFns = []

export { render, staticRenderFns }