import _get from 'lodash/get'
import { OCCUPANCY } from '@/constants'

const ReservationsMixin = {
  methods: {
    getDeskReservedFor(desk) {
      const isGuestInvited = _get(
        desk,
        'context.isGuestInvited',
        false
      )
      const reservedByUserName = `${desk.context?.reservedBy
        ?.firstName || ''} ${desk.context?.reservedBy?.lastName ||
        ''}`
      const reservedForUserName = `${desk.context?.firstName ||
        ''} ${desk.context?.lastName || ''}`
      if (!reservedForUserName || !reservedByUserName) {
        return '***'
      }

      if (desk.status === OCCUPANCY.FREE) {
        return ''
      }
      let label = ''
      if (!desk.showUserDetails) {
        label = '***'
      } else {
        if (
          desk.status !== OCCUPANCY.FIXED &&
          reservedByUserName &&
          reservedByUserName !== reservedForUserName
        ) {
          label += `${this.$t('by')} ${reservedByUserName}`
        }
        label += ` ${this.$t('for')} ${reservedForUserName}`
      }
      if (desk.context && desk.context.isEmergencyResponder) {
        label += ` ${this.$t('emergencyResponder')}`
      }
      if (
        isGuestInvited &&
        reservedByUserName !== reservedForUserName
      ) {
        label += ` ${this.$t('andGuests')} `
      }
      if (
        isGuestInvited &&
        reservedByUserName === reservedForUserName
      ) {
        label += ` ${this.$t('forGuests')} `
      }

      return label
    },
    getRoomReservedFor(room) {
      const reservedByUserName = `${room.context?.reservedBy
        ?.firstName || ''} ${room.context?.reservedBy?.lastName ||
        ''}`
      const reservedForUserName = `${room.context?.firstName ||
        ''} ${room.context?.lastName || ''}`

      const isGuestInvited = _get(
        room,
        'context.isGuestInvited',
        false
      )

      if (!reservedForUserName || !reservedByUserName) {
        return '***'
      }
      if (room.status === OCCUPANCY.FREE) {
        return ''
      }
      let label = ''
      if (!room.showUserDetails) {
        label = '***'
      } else if (
        reservedByUserName === reservedForUserName &&
        !isGuestInvited
      ) {
        label += `${this.$t('for')}
                  ${reservedForUserName}`
        if (_get(room, 'context.isEmergencyResponder')) {
          label += ` ${this.$t('emergencyResponder')}`
        }
      } else if (
        reservedByUserName === reservedForUserName &&
        isGuestInvited
      ) {
        label += `${this.$t('by')}
                  ${reservedByUserName} ${this.$t('forGuests')}`
      } else if (
        reservedByUserName !== reservedForUserName &&
        !isGuestInvited
      ) {
        label += `${this.$t('by')}
                  ${reservedByUserName} ${this.$t(
          'for'
        )} ${reservedForUserName}`
      } else if (
        reservedByUserName !== reservedForUserName &&
        isGuestInvited
      ) {
        label += `${this.$t('by')}
                  ${reservedByUserName} ${this.$t(
          'for'
        )} ${reservedForUserName} ${this.$t('andGuests')}`
      }
      return label
    }
  }
}

export default ReservationsMixin
